import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { rxResource, takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Application, CustomerService, Feature, UserService } from 'apps/suite/src/app/shared/access';
import { FeaturesService } from 'apps/suite/src/app/shared/access/features.service';
import { BehaviorSubject, catchError, combineLatest, filter, firstValueFrom, map, of, switchMap, tap } from 'rxjs';
import { ApplicationStorageService } from './storage';

@Injectable({ providedIn: 'root' })
export class AppService {
  private http = inject(HttpClient);
  private title = inject(Title);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private storage = inject(ApplicationStorageService);
  private customerService = inject(CustomerService);
  private featureService = inject(FeaturesService);
  private userService = inject(UserService);

  apps = rxResource({
    request: () => ({
      customerID: this.customerService.selectedCustomer()?.customerID,
      features: this.featureService.features.value(),
    }),
    loader: ({ request }) => {
      if (request.customerID == null) return of(this.storage.getItem('cache.access.apps', []));
      return this.http.get<Application[]>(`/api/access/Application/${request.customerID}`).pipe(
        tap((a) => this.storage.setItem('cache.access.apps', a)),
        map((apps) => {
          const invoiceFeature = request.features.find((f: Feature) => f.featureName === 'app-sales-invoice');
          const appIdx = apps.findIndex((a) => a.applicationID === 5);
          if (invoiceFeature) {
            if (appIdx < 0) {
              apps = [
                ...apps,
                // Add Invoice app if user has access to invoice feature and it is not provided in application list

                // TODO: This should not be hardcoded in here. Investigate if this can be added in the rails.component
                // like we do with `asset-log`. It can only be moved if we actually do not use the applicationID for
                // `Invoice` for anything important.
                {
                  applicationID: 5,
                  applicationName: 'Invoice',
                  applicationUrl: '/invoice/',
                  description: 'Display and download of customer invoices and credit notes',
                  // Access is granted if customer has access to the invoice feature
                  // and the user has at least read access to the financial privilege

                  // NOTE: We cannot use the check in accessService here, as this would cause
                  // a circular dependency. Besides, the featurename ("app-sales-invoice") and the privilege
                  // name ("Financial") differs, so it would not be an easy check anyway.
                  hasAccess:
                    invoiceFeature != null &&
                    // Should only show invoice if user has access to the financial privilege on customer level
                    // It is possible to set financial privilege on lower levels, but then the privilege
                    // is for financial widgets and not the invoice module.
                    this.userService
                      .currentUser()
                      ?.privileges.find(
                        (p) =>
                          p.name.toLowerCase() === 'financial' &&
                          p.resourceId === (this.customerService.selectedCustomer()?.customerID ?? '-1') &&
                          p.resourceType === 'customer' &&
                          p.read,
                      ) != null,
                  logoUrl: 'invoice',
                  subscriptions: [],
                },
              ];
            }
          } else if (appIdx > -1) {
            // Feature is not available, remove Invoice app if it exists in the application list
            apps = apps.filter((a) => a.applicationID !== 5);
          }
          return apps;
        }),
        catchError(() => of([] as Application[])),
      );
    },
  });
  apps$ = toObservable<Application[]>(this.apps.value).pipe(filter((a) => !!a && a.length > 0));

  accessibleApps = computed<Application[]>(
    () => this.apps.value()?.filter((a: Application) => a.hasAccess == true) ?? [],
  );

  private currentApplicationID = signal<number | undefined>(undefined);
  application$ = toObservable(this.currentApplicationID).pipe(filter((id) => id != null));
  currentApplication = computed<Application>(() => {
    const appID = this.getApplicationID();
    const rootApp = {
      applicationID: 0,
      applicationName: 'Logic Root',
      applicationUrl: '/',
      description: 'Logic Root',
      hasAccess: true,
      subscriptions: [],
      logoUrl: 'suite',
    };
    if (!appID || +appID === 0) return rootApp;
    return this.apps.value()?.find((a: Application) => +a.applicationID === +appID) ?? rootApp;
  });

  version = signal('');

  private previousUrl?: string;
  private currentUrl?: string;

  titleChanged$ = new BehaviorSubject<string>('');

  constructor() {
    // Auto read version for each app using this library, and set as attribute on document body
    firstValueFrom(
      this.http.get(`assets/version.txt?d=${new Date().getTime()}`, {
        headers: { NoLoad: 'true' },
        responseType: 'text',
      }),
    ).then((res) => {
      document.body.setAttribute('version', res.trim());
      this.version.set(res.trim());
    });

    // Autoset applicationID on route change
    combineLatest([
      this.apps$,
      this.router.events.pipe(
        filter((event) => event instanceof NavigationStart),
        map((e) => e as NavigationStart),
      ),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: ([apps, event]: [Application[], NavigationStart]) => {
          try {
            const url = event.url.substring(1).split('/')[0].split('?')[0];
            if (!['help', 'settings', 'select-customer', 'login', 'callback', 'palette', 'config'].includes(url)) {
              // Find application by url
              const currentApp = apps?.find((a) => a.applicationUrl.split('/').join('') === url);
              let appID = currentApp?.applicationID;
              // If not found, see if app ID is allready set
              if (appID == null) appID = this.getApplicationID();
              // If still not found, set to default
              if (appID == null) appID = 1; // Energy - default
              this.setApplicationID(appID);
            } else if (['config'].includes(url)) {
              this.setApplicationID(0);
            }
          } catch (ex) {
            //
          }
        },
        error: (err) => console.error(err),
      });

    // Track routes
    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((e) => e instanceof NavigationEnd),
        switchMap(() => this.route.queryParams),
      )
      .subscribe(() => {
        if (this.router.url.split('?')[0] !== this.currentUrl?.split('?')[0]) {
          // Only set previous url if the route has actually changed.
          // This excludes changes in query params
          const newParentRoute = this.router.url.substring(0, this.router.url.lastIndexOf('/'));
          const curParentRoute = this.currentUrl?.substring(0, this.currentUrl?.lastIndexOf('/'));
          if (!(this.currentUrl?.endsWith('/new') && newParentRoute === curParentRoute)) {
            // If we are redirecting from '/new' to an actual stored id, just replace url so we can
            // safely go back
            this.previousUrl = this.currentUrl;
          }
        }
        // Always replace current url, even with changes in query params
        // This will respect search and filtering
        this.currentUrl = this.router.url;
      });
  }

  getPreviousUrl() {
    return this.previousUrl != null ? decodeURIComponent(this.previousUrl) : undefined;
  }

  getApplicationID = this.currentApplicationID.asReadonly();
  setApplicationID(id: number) {
    if (this.currentApplicationID() !== id) {
      this.currentApplicationID.set(id);
    }
  }

  getTitleBase = computed(() => {
    // prettier-ignore
    switch (this.currentApplicationID()) {
      case 10: return 'Flex';
      case 11: return 'Flx Admin';
      case 12: return 'Flx Terminal';
      case 20: return 'Logic BS';
    }
    return 'Noova Energy';
  });

  getApplicationName = computed(() => {
    // prettier-ignore
    switch (this.currentApplicationID()) {
      // Logic Suite
      case 0: return 'Noova Energy';
      case 1: return 'Energy';
      case 2: return 'Notifications';
      case 3: return 'Sensors';
      case 4: return 'Facility';
      case 5: return 'Dashboard';
      // FLEX
      case 10: return 'Flx';
      case 11: return 'Flx Admin';
      case 12: return 'Flx Terminal';
      // LBS
      case 20: return 'Logic Business Solutions';
    }
    return '';
  });

  setTitle(part: string) {
    const title = this.getTitleBase();
    this.title.setTitle(part ? `${part} | ${title}` : title);
    this.titleChanged$.next(this.getTitle());
  }

  getTitle(): string {
    return this.title.getTitle();
  }
}
